<template>
  <div class="cy_news">
    <div class="cy_news_bg" v-if="Object.keys(basicData).length != 0">
      <img :src="basicData.basicImg" alt="" style="width:100%" />
    </div>
    <ul class="cy_news_head">
      <li
        v-for="(item, idx) in headList"
        :key="idx"
        :class="[active == idx ? 'li_on' : '']"
        @click="switchList(item, idx)"
      >
        <a :href="'#/cy_news?currentIdx=' + active">
          {{ item.name }}
        </a>
      </li>
    </ul>
    <div class="cy_news_nav" v-if="!currentId">
      <ul class="cy_news_list" v-if="newList.length != 0">
        <li v-for="(item, idx) in newList" :key="idx" @click="goDetails(item)">
          <a :href="'#/cy_news?currentIdx=' + active + '&currentId=' + item.id">
            <div class="list_image">
              <img :src="item.cover_image" alt="" style="width:100%;" />
            </div>
            <div class="list_nav">
              <h2>{{ item.title }}</h2>
              <p style="margin-bottom:0.1rem">{{ item.miaoshu }}</p>
              <p>{{ item.create_time }}</p>
            </div>
          </a>
        </li>
        <onLoading :show="loading"></onLoading>
      </ul>
      <div class="details_empty" v-else>
        <img
          src="@/assets/image/xwdt/xwdt-data.webp"
          alt=""
          style="margin:0 auto"
        />
      </div>
      <div class="all-pagination">
        <el-pagination
          prev-text="上一页"
          next-text="下一页"
          @current-change="handleCurrentChange"
          :current-page.sync="form.page"
          background
          :page-size="form.pagesize"
          layout="prev, pager, next,total"
          :total="total"
          v-if="total > form.pagesize"
        >
        </el-pagination>
        <span class="no_meet_conditions" v-else>暂无更多数据</span>
      </div>
    </div>
    <div class="cy_news_nav2" v-else>
      <div class="nav2_html" v-html="newsData.maincontent"></div>
    </div>
  </div>
</template>

<script>
import { getPageMsg, getXwTypeData, getAllXwData, getXwxqData } from '@/api/api'
import onLoading from '@/common/Loading/onLoading.vue'
export default {
  components: { onLoading },
  computed: {},
  data () {
    return {
      active: 0,
      basicData: {},
      headList: [],
      newList: {},
      loading: false,
      form: {
        page: 1,
        pagesize: 6
      },
      type_id: '',
      project: '',
      total: 0,
      currentId: 0,
      newsData: {}
    }
  },
  watch: {
    $route: {
      // 监听query参数变化
      handler () {
        if (this.$route.query.currentIdx) {
          this.active = this.$route.query.currentIdx
        } else {
          this.active = 0
          this.type_id = ''
          this.getSjdqList(1, '', this.project)
        }
        if (this.$route.query.currentId) {
          this.currentId = this.$route.query.currentId
            this.goDetails({ id: this.currentId })
        } else {
          this.currentId = 0
        }
      },
      deep: true
    }
  },
  methods: {
    getData () {
      let that = this
      if (this.$route.query.currentIdx) {
        this.active = this.$route.query.currentIdx
      } else {
        this.active = 0
      }
      if (this.$route.query.currentId) {
        this.currentId = this.$route.query.currentId
        this.goDetails({ id: this.currentId })
      } else {
        this.currentId = 0
      }
      let id = sessionStorage.getItem(that.changeData() + 'id')
      that.project = sessionStorage.getItem(this.changeData() + 'project')
      getPageMsg(id).then(res => {
        if (res.data.code == 1) {
          that.basicData = res.data.data.basic
        }
      })
      getXwTypeData(1, that.project).then(res => {
        if (res.data.code == 1) {
          that.headList = res.data.data
        }
      })
      that.getSjdqList(1, '', that.project)
    },
    getSjdqList (i, x, d) {
      this.loading = true
      getAllXwData(i, x, d).then(res => {
        if (res.data.code == 1) {
          this.newList = res.data.data.list
          this.total = res.data.data.total
          this.loading = false
        }
      })
    },
    switchList (e, i) {
      this.active = i
      this.currentId = 0
      this.type_id = e.id
      this.getSjdqList(1, e.id, this.project)
    },
    goDetails (e) {
      this.currentId = e.id
      this.newsData = {}
      getXwxqData(e.id, this.form.project).then(res => {
        if (res.data.code == 1) {
          this.newsData = res.data.data
        }
      })
    },
    handleCurrentChange (val) {
      this.form.page = val
      this.getSjdqList(val, this.type_id, this.project)
    },
    toLink (i) {
      sessionStorage.setItem(this.changeData() + 'id', i)
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.cy_news {
  width: 100%;
  height: 100%;
  color: #212529;
  .cy_news_head {
    width: 100%;
    border-bottom: 1px solid #d0d0d0;
    white-space: nowrap;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    li {
      margin: 0 20px;
      font-size: 18px;
      padding: 30px 0;
      box-sizing: border-box;
      cursor: pointer;
    }
    .li_on {
      border-bottom: 3px solid #c8151d;
      font-weight: bold;
    }
  }
  .cy_news_nav {
    width: 100%;
    .nav_jmtj_title {
      font-size: 32px;
      text-align: center;
      margin: 50px 0 80px 0;
    }
    .nav_jmtj_title:after {
      content: '';
      width: 30px;
      height: 4px;
      display: block;
      margin: 0 auto;
      background: #fabe0d;
      margin-top: 15px;
    }
    .cy_news_list,
    .details_empty {
      width: calc(15rem - 0.8rem);
      display: flex;
      flex-wrap: wrap;
      margin: 0 auto;
      position: relative;
      background-color: #fff;
      padding: 0.4rem;
      li {
        border-bottom: 1px solid #f0f2f5;
        padding: 0.2rem;
        margin-bottom: 0.3rem;
        display: inline-block;
        font-size: 0.16rem;
        vertical-align: middle;
        width: 100%;
        cursor: pointer;
        a {
          display: flex;
        }
        .list_image {
          width: 2.5rem;
          display: flex;
          justify-content: center;
          padding-right: 0.2rem;
        }
        .list_nav {
          width: calc(100% - 2.7rem);
          line-height: 1.5;
          h2 {
            margin: 0 0 0.2rem;
            font-size: 0.2rem;
            font-weight: 600;
          }
          h2:hover {
            color: #0a8ce2;
          }
        }
      }
      li:hover {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      }
    }
    .details_empty {
      width: 15rem;
      background-color: #fff;
    }
    .all-pagination {
      padding-bottom: 35px;
    }
  }
  .cy_news_nav2 {
    width: 15.2rem;
    margin: 50px auto;
    .nav2_html {
      line-height: 1.5;
      /deep/img {
        max-width: 100% !important;
        margin: 0 auto !important;
        display: flex;
      }
      /deep/div {
        max-width: 100% !important;
      }
    }
  }
}
</style>
